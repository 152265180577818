@font-face {
  font-family: 'DejaVu Sans';
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf')
    format('truetype');
}

#orcamento-preview {
  font-family: 'DejaVu Sans', 'Arial', sans-serif;
}
.table-preview {
  border-collapse: collapse;
  width: 100%;
}

.table-preview td,
.table-preview th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.information {
  margin-top: 20px;
  font-size: 18px;
}

.table-preview .table-title {
  text-align: center;
  background-color: #f18750;
  color: #fff;
  font-size: 18px;
}

.table-preview th,
.table-preview td,
.table-preview td input {
  font-size: 16px;
}

input {
  border: none;
  width: 100%;
}

input:focus {
  border: none;
}

.title {
  text-align: center;
  line-height: 24px;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-bottom: 10px;
}

.orcamento-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.orcamento-info p {
  font-size: 14px;
}

.fields {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.desconto-container {
  width: 20%;
  margin-top: 20px;
}

.table-preview .text-total {
  font-size: 16px;
}

.text-subtotal,
.text-desconto {
  font-size: 14px;
}

.observacoes {
  margin-top: 15px;
  font-weight: 500;
}

.total-column td {
  border: none;
}

.items-img {
  width: 50px;
}

table .background {
  background-image: url('/static/images/logo/logo-cinza.png');
  background-repeat: repeat;
  background-size: 25% auto;
}

.input-adornment {
  position: relative;
  display: inline-block;
  max-width: 50px;
  border: none !important;
}

.input-adornment::after {
  position: absolute;
  right: 5px;
  top: 8px;
  content: attr(data-placeholder);
  pointer-events: none;
}
