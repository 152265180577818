.container {
  overflow-x: auto  !important; /* Permite a rolagem horizontal */
  overflow-y: hidden  !important; /* Desativa a rolagem vertical */
}
.container ::-webkit-scrollbar {
    display: initial  !important;
    height: 10px;
  }

.container ::-webkit-scrollbar-thumb {
  background: #F18750  !important;
  border-radius: 20px;
}
